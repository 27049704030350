import { page } from './page/page.reducer';
import { global } from './global/global.reducer';
import { people } from './people/people.reducer';
import { portfolio } from './portfolio/portfolio.reducer';
import { news } from './news/news.reducer';
import { search } from './search/search.reducer';
import { playerModal } from './modal/playerModal.reducer';
import { pageModal } from './pageModal/pageModal.reducer';
import { caseStudy } from './caseStudy/caseStudy.reducer';
import { reports } from './reports/reports.reducer';

import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
    page,
    global,
    people,
    portfolio,
    news,
    search,
    playerModal,
    pageModal,
    caseStudy,
    reports,
});
