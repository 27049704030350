export const OPEN_PLAYER_MODAL = 'OPEN_PLAYER_MODAL';
export const CLOSE_PLAYER_MODAL = 'CLOSE_PLAYER_MODAL';
export const UPDATE_PLAYER_LINK = 'UPDATE_PLAYER_LINK';
export const UPDATE_PLAYER_POSTER = 'UPDATE_PLAYER_POSTER';

export const openPlayerModal = () => {
    return async dispatch => {
        dispatch({ type: OPEN_PLAYER_MODAL });
    };
};

export const closePlayerModal = () => {
    return async dispatch => {
        dispatch({ type: CLOSE_PLAYER_MODAL });
    };
};

export const updatePlayerLink = link => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PLAYER_LINK,
            payload: {
                link: link,
            },
        });
    };
};

export const updatePlayerPoster = poster => {
    return async dispatch => {
        dispatch({
            type: UPDATE_PLAYER_POSTER,
            payload: {
                poster: poster,
            },
        });
    };
};
