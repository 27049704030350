import { internalInstance } from '../../utils/httpinstance';

export const GET_GLOBAL_START = 'GET_GLOBAL_START';
export const GET_GLOBAL_SUCCESS = 'GET_GLOBAL_SUCCESS';
export const GET_GLOBAL_ERROR = 'GET_GLOBAL_ERROR';
export const UPDATE_GLOBAL_DATA = 'UPDATE_GLOBAL_DATA';
export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';

export const getGlobal = lang => {
    return async dispatch => {
        dispatch({ type: GET_GLOBAL_START });
        await internalInstance
            .get(`/api/global?language=${lang}`)
            .then(res => {
                dispatch({
                    type: GET_GLOBAL_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                    lang: lang,
                });
            })
            .catch(() => {
                dispatch({ type: GET_GLOBAL_ERROR });
            });
    };
};

export const setCookieConsent = accept => {
    return async dispatch => {
        dispatch({
            type: SET_COOKIE_CONSENT,
            payload: {
                cookieAccept: accept,
            },
        });
    };
};
