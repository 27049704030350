import { fromJS } from 'immutable';

import { OPEN_PLAYER_MODAL, CLOSE_PLAYER_MODAL, UPDATE_PLAYER_LINK, UPDATE_PLAYER_POSTER } from './playerModal.action';

const initialState = fromJS({
    open: false,
    link: '',
});

export const playerModal = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_PLAYER_MODAL:
            return state.withMutations(ctx => {
                ctx.set('open', true);
            });
        case CLOSE_PLAYER_MODAL:
            return state.withMutations(ctx => {
                ctx.set('open', false);
            });
        case UPDATE_PLAYER_LINK:
            return state.withMutations(ctx => {
                ctx.set('link', action.payload?.link);
            });
        case UPDATE_PLAYER_POSTER:
            return state.withMutations(ctx => {
                ctx.set('poster', action.payload?.poster);
            });
        default:
            return state;
    }
};
