import * as Serialize from '@redux-devtools/serialize'
import * as reduxPersist from 'redux-persist'
import * as Immutable from 'immutable'

export const immutableTransform = (config) => {
  config = config || {}

  const serializer = Serialize.immutable(Immutable, config.records)

  return reduxPersist.createTransform(serializer.stringify, serializer.parse, config)
}
