import { internalInstance } from '../../utils/httpinstance';

export const ADD_CS_FILTER = 'ADD_CS_FILTER';
export const CLEAR_CS_FILTERS = 'CLEAR_CS_FILTERS';
export const GET_CASESTUDY_START = 'GET_CASESTUDY_START';
export const GET_CASESTUDY_SUCCESS = 'GET_CASESTUDY_SUCCESS';
export const GET_CASESTUDY_ERROR = 'GET_CASESTUDY_ERROR';

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_CS_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_CS_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

export const fetchCaseStudy = (page, sort) => {
    return async dispatch => {
        dispatch({ type: GET_CASESTUDY_START });
        await internalInstance
            .get(`/api/caseStudy?page=${page}&&sort=${sort}`)
            .then(res => {
                dispatch({
                    type: GET_CASESTUDY_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_CASESTUDY_ERROR });
            });
    };
};
