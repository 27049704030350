import { internalInstance, cmsInstance } from '../../utils/httpinstance';

export const GET_PAGE_START = 'GET_PAGE_START';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';

export const getPage = (path, lang, preview, pageId) => {
    if (!preview || preview === 'undefined') {
        preview = false;
    }
    return async dispatch => {
        dispatch({ type: GET_PAGE_START });
        if (preview) {
            await cmsInstance
                .get(
                    `/api/getpage?path=/${encodeURIComponent(
                        path
                    )}&preview=${preview}`
                )
                .then(res => {
                    dispatch({
                        type: GET_PAGE_SUCCESS,
                        payload: {
                            data: res.data,
                        },
                    });
                })
                .catch(() => {
                    dispatch({ type: GET_PAGE_ERROR });
                });
        } else {
            await internalInstance
                .get(
                    `/api/page?path=${encodeURIComponent(
                        path
                    )}&preview=${preview}`
                )
                .then(res => {
                    dispatch({
                        type: GET_PAGE_SUCCESS,
                        payload: {
                            data: res.data,
                        },
                    });
                })
                .catch(() => {
                    dispatch({ type: GET_PAGE_ERROR });
                });
        }
    };
};

export const getDummyPage = (path, lang, preview, pageId) => {
    return async dispatch => {
        dispatch({ type: GET_PAGE_START });
        await internalInstance
            .get(
                `/api/dummypage?path=${encodeURIComponent(
                    path
                )}&lang=${lang}&preview=${preview}&pageId=${encodeURIComponent(pageId)}`
            )
            .then(res => {
                dispatch({
                    type: GET_PAGE_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_PAGE_ERROR });
            });
    };
};
