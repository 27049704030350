import { internalInstance } from '../../utils/httpinstance';
import AbortController from 'abort-controller';

export const ADD_FILTER = 'ADD_NEWS_FILTER';
export const REMOVE_NEWS_FILTER = 'REMOVE_NEWS_FILTER';
export const SET_FILTERS = 'SET_NEWS_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_NEWS_FILTERS';
export const GET_NEWS_START = 'GET_NEWS_START';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_ERROR = 'GET_NEWS_ERROR';

let abortController;

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const removeFilter = filter => {
    return async dispatch => {
        dispatch({
            type: REMOVE_NEWS_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const setFilters = filters => {
    return async dispatch => {
        dispatch({
            type: SET_FILTERS,
            payload: {
                filters: filters,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

// Currently Supports apiEndpoint:  news & in-the-news = /api/news & /api/in-the-news
export const fetchNews = (path, page, sort, filters, append = false, apiEndpoint = 'news') => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
    return async dispatch => {
        dispatch({ type: GET_NEWS_START });
        await internalInstance
            .get(`/api/${apiEndpoint}?path=${path}&page=${page}&sort=${sort}&filters=${filters}`, {
                signal: abortController.signal,
            })
            .then(res => {
                dispatch({
                    type: GET_NEWS_SUCCESS,
                    payload: {
                        data: res.data,
                        append,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_NEWS_ERROR });
            });
    };
};
