import { fromJS, Map } from 'immutable';
import { getCookieConsentValue } from 'react-cookie-consent';

import { GET_GLOBAL_START, GET_GLOBAL_SUCCESS, GET_GLOBAL_ERROR, SET_COOKIE_CONSENT } from './global.action';

const initialState = fromJS({
    globalData: {},
    globalInit: false,
    globalLoading: false,
    globalLang: '',
    cookieAccept: getCookieConsentValue() === 'true',
});

export const global = (state = initialState, action) => {
    switch (action.type) {
        case GET_GLOBAL_START:
            return state.withMutations(ctx => {
                ctx.set('globalInit', true);
                ctx.set('globalLoading', true);
                ctx.set('globalData', new Map({}));
                ctx.set('globalLang', new Map(action.lang));
            });
        case GET_GLOBAL_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('globalLoading', false);
                ctx.set('globalData', new Map(action.payload));
            });
        case GET_GLOBAL_ERROR:
            return state.withMutations(ctx => {
                ctx.set('globalLoading', true);
                ctx.set('globalData', new Map({}));
            });
        case SET_COOKIE_CONSENT:
            return state.withMutations(ctx => {
                ctx.set('cookieAccept', action.payload?.cookieAccept);
            });
        default:
            return state;
    }
};
