import { fromJS, Map } from 'immutable';

import { SEARCH_START, SEARCH_SUCCESS, SEARCH_ERROR, RESET_SEARCH } from './search.action';

const initialState = fromJS({
    result: {},
    success: false,
    loading: false,
});

export const search = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_START:
            return state.withMutations(ctx => {
                ctx.set('success', false);
                ctx.set('loading', true);
                ctx.set('result', new Map({}));
            });
        case SEARCH_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('success', true);
                ctx.set('loading', false);
                ctx.set('result', new Map(action.payload.data));
            });
        case SEARCH_ERROR:
            return state.withMutations(ctx => {
                ctx.set('loading', false);
                ctx.set('result', new Map({}));
            });
        case RESET_SEARCH:
            return state.withMutations(ctx => {
                ctx.set('success', false);
                ctx.set('loading', false);
                ctx.set('result', new Map({}));
            });
        default:
            return state;
    }
};
