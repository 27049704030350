import { fromJS, Map } from 'immutable';

import { GET_PAGE_MODAL_START, GET_PAGE_MODAL_SUCCESS, GET_PAGE_MODAL_ERROR } from './pageModal.action';

const initialState = fromJS({
    page: {},
    pageInit: false,
    pageLoading: false,
});

export const pageModal = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAGE_MODAL_START:
            return state.withMutations(ctx => {
                ctx.set('pageInit', true);
                ctx.set('pageLoading', true);
                ctx.set('page', new Map({}));
            });
        case GET_PAGE_MODAL_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('pageLoading', false);
                ctx.set('page', new Map(action.payload.data));
            });
        case GET_PAGE_MODAL_ERROR:
            return state.withMutations(ctx => {
                ctx.set('pageLoading', true);
                ctx.set('page', new Map({}));
            });
        default:
            return state;
    }
};
