import axios from 'axios';
import ExecutionEnvironment from 'exenv';

export const cmsInstance = axios.create({
    baseURL: process.env.CMS_BASE,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const cmsInstanceDummy = axios.create({
    baseURL: 'https://virtserver.swaggerhub.com/joe.chan/Permira/1.0.2',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

export const internalInstance = axios.create({
    baseURL: !ExecutionEnvironment.canUseDOM && process.env.LOCAL_BASE ? process.env.LOCAL_BASE : undefined,
    // baseURL: 'https://95a0-203-184-213-11.jp.ngrok.io',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});
