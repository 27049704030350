import { internalInstance } from '../../utils/httpinstance';

export const ADD_REPORTS_FILTER = 'ADD_REPORTS_FILTER';
export const REMOVE_REPORTS_FILTER = 'REMOVE_REPORTS_FILTER';
export const CLEAR_REPORTS_FILTERS = 'CLEAR_REPORTS_FILTERS';
export const GET_REPORTS_START = 'GET_REPORTS_START';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_REPORTS_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const removeFilter = filter => {
    return async dispatch => {
        dispatch({
            type: REMOVE_REPORTS_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_REPORTS_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

export const fetchReports = (page, filters, sort) => {
    return async dispatch => {
        dispatch({ type: GET_REPORTS_START });
        await internalInstance
            .get(`/api/reports?page=${page}&filters=${filters}&sort=${sort}`)
            .then(res => {
                dispatch({
                    type: GET_REPORTS_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_REPORTS_ERROR });
            });
    };
};
