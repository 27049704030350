import { internalInstance } from '../../utils/httpinstance';
import AbortController from 'abort-controller';

export const ADD_FILTER = 'ADD_PEOPLE_FILTER';
export const REMOVE_FILTER = 'REMOVE_PEOPLE_FILTER';
export const SET_FILTERS = 'SET_PEOPLE_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_PEOPLE_FILTERS';
export const GET_PEOPLE_START = 'GET_PEOPLE_START';
export const GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';
export const GET_PEOPLE_ERROR = 'GET_PEOPLE_ERROR';

let abortController;

export const setFilters = filters => {
    return async dispatch => {
        dispatch({
            type: SET_FILTERS,
            payload: {
                filters: filters,
            },
        });
    };
};

export const addFilter = filter => {
    return async dispatch => {
        dispatch({
            type: ADD_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const removeFilter = filter => {
    return async dispatch => {
        dispatch({
            type: REMOVE_FILTER,
            payload: {
                filter: filter,
            },
        });
    };
};

export const clearFilters = type => {
    return async dispatch => {
        dispatch({
            type: CLEAR_FILTERS,
            payload: {
                type: type,
            },
        });
    };
};

export const fetchPeoples = (page, filters, sort, append = false) => {
    if (abortController) {
        abortController.abort();
    }
    abortController = new AbortController();
    return async dispatch => {
        dispatch({ type: GET_PEOPLE_START });
        await internalInstance
            .get(`/api/peoples?page=${page}&filters=${filters}&sort=${sort}`, {
                signal: abortController.signal,
            })
            .then(res => {
                dispatch({
                    type: GET_PEOPLE_SUCCESS,
                    payload: {
                        data: res.data,
                        append,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: GET_PEOPLE_ERROR });
            });
    };
};

export const clearPeoples = () => {
    const abort = false;
    return async dispatch => {
        dispatch({ type: GET_PEOPLE_START });
        dispatch({
            type: GET_PEOPLE_SUCCESS,
            payload: {
                data: [],
                abort,
            }
        });
    };
}
