import { internalInstance } from '../../utils/httpinstance';

export const SEARCH_START = 'SEARCH_START';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const RESET_SEARCH = 'RESET_SEARCH';

export const search = (keywords, types, page) => {
    return async dispatch => {
        dispatch({ type: SEARCH_START });
        await internalInstance
            .get(`/api/search?keywords=${encodeURIComponent(keywords)}&types=${encodeURIComponent(types)}&page=${page}`)
            .then(res => {
                dispatch({
                    type: SEARCH_SUCCESS,
                    payload: {
                        data: res.data,
                    },
                });
            })
            .catch(() => {
                dispatch({ type: SEARCH_ERROR });
            });
    };
};

export const reset = () => {
    return async dispatch => {
        dispatch({ type: RESET_SEARCH });
    };
};
