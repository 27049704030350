import { fromJS, Set, Map } from 'immutable';
import { isArray } from 'lodash';

import {
    ADD_FILTER,
    REMOVE_FILTER,
    CLEAR_FILTERS,
    GET_PEOPLE_START,
    GET_PEOPLE_SUCCESS,
    GET_PEOPLE_ERROR,
    SET_FILTERS,
} from './people.action';

const initialState = fromJS({
    filters: new Set(),
    peoples: {},
    peoplesInit: false,
    peoplesLoading: false,
});

export const people = (state = initialState, action) => {
    switch (action.type) {
        case ADD_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(
                    f => f.type === filter.type && (filter.single || f.value === filter.value)
                );
                if (thisFilter.length > 0) {
                    let newFilters = filters.delete(thisFilter[0]);
                    if (filter.single) {
                        newFilters = newFilters.add(filter);
                    }
                    ctx.set('filters', newFilters);
                } else {
                    ctx.set('filters', filters.add(filter));
                }
            });
        case REMOVE_FILTER:
            return state.withMutations(ctx => {
                const { filter } = action.payload;
                const filters = ctx.get('filters');
                const thisFilter = Array.from(filters).filter(
                    f => f.type === filter.type && (filter.single || f.value === filter.value)
                );
                if (thisFilter.length > 0) {
                    let newFilters = filters.delete(thisFilter[0]);
                    if (filter.single) {
                        newFilters = newFilters.add(filter);
                    }
                    ctx.set('filters', newFilters);
                }
            });
        case SET_FILTERS:
            return state.withMutations(ctx => {
                const { filters } = action.payload;
                ctx.set('filters', new Set(filters));
            });
        case CLEAR_FILTERS:
            return state.withMutations(ctx => {
                const { type } = action.payload;
                const filters = ctx.get('filters');
                if (type === '') {
                    ctx.set('filters', new Set());
                } else if (isArray(type)) {
                    const newFilters = Array.from(filters).filter(f => !type.includes(f.type));
                    ctx.set('filters', new Set(newFilters));
                } else {
                    const newFilters = Array.from(filters).filter(f => f.type !== type);
                    ctx.set('filters', new Set(newFilters));
                }
            });
        case GET_PEOPLE_START:
            return state.withMutations(ctx => {
                ctx.set('peoplesInit', true);
                ctx.set('peoplesLoading', true);
            });
        case GET_PEOPLE_SUCCESS:
            return state.withMutations(ctx => {
                ctx.set('peoplesLoading', false);
                if (action.payload.append) {
                    const people = ctx.get('peoples').toJS();
                    const data = people.data || [];
                    action.payload.data.data = [...data, ...action.payload.data.data];
                    ctx.set('peoples', new Map(action.payload.data));
                } else {
                    ctx.set('peoples', new Map(action.payload.data));
                }
            });
        case GET_PEOPLE_ERROR:
            return state.withMutations(ctx => {
                ctx.set('peoplesLoading', true);
                ctx.set('peoples', new Map({}));
            });
        default:
            return state;
    }
};
